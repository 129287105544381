import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable/symbol';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FormsCenterProvider } from './Context/FormsCenterContext';

ReactDOM.render(
  <React.StrictMode>
    <FormsCenterProvider>
      <App />
    </FormsCenterProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
