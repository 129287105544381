import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import SignUp from './SignUp';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SignUpModal({
  openSignUp,
  handleCloseSignUp,
  handleOpenSignIn,
}) {
  return (
    <Modal
      open={openSignUp}
      onClose={handleCloseSignUp}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          ...style,
          width: { xs: '100%', sm: '85%', md: '75%', lg: '50%' },
        }}
      >
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          <SignUp
            SignUpClose={handleCloseSignUp}
            SignInOpen={handleOpenSignIn}
          />
        </Typography>
      </Box>
    </Modal>
  );
}
