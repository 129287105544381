import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ForgetPassword from './ForgetPassword';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ForgetPasswordModal({
  openForgetPassword,
  handleCloseForgetPassword,
}) {
  return (
    <Modal
      open={openForgetPassword}
      onClose={handleCloseForgetPassword}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          ...style,
          width: { xs: '100%', sm: '85%', md: '75%', lg: '50%' },
        }}
      >
        <ForgetPassword ForgetPasswordClose={handleCloseForgetPassword} />
      </Box>
    </Modal>
  );
}
