import React, { useContext, useState } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CreateIcon from '@mui/icons-material/Create';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import { FormsCenterContext } from '../../Context/FormsCenterContext';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function RenameForm({
  formDataId,
  formName,
  setFormName,
  RenameFormClose,
}) {
  const [open, setOpen] = useState(false);
  const [customFormName, setCustomFormName] = useState('');
  const formsObject = useContext(FormsCenterContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'PUT',
      data: {
        formDataId,
        customFormName,
      },
      url: `${formsObject.putCustomFormName}`,
    }).then((res) => {
      if (res.status === 200) {
        setFormName(customFormName);
        RenameFormClose(event);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} sx={{ width: '100%' }}>
          Form Name Updated!!
        </Alert>
      </Snackbar>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Link
          href='#'
          underline='none'
          onClick={RenameFormClose}
          sx={{ position: 'absolute', top: 15, right: 15 }}
        >
          X
        </Link>
        <Stack direction='row' spacing={2}>
          <CreateIcon />
          <Typography component='h2' variant='h4'>
            Rename Form
          </Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mt: 4,
          }}
        >
          <TextField
            name='customFormName'
            required
            id='customFormName'
            label='Form Name'
            placeholder='Edit Title'
            onChange={(e) => {
              setCustomFormName(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <CheckIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: '100%' }}
          />
        </Box>
        <Stack direction='row' spacing={2} sx={{ mt: 4 }}>
          <Button
            variant='outlined'
            size='medium'
            onClick={RenameFormClose}
            sx={{ display: 'inline-block' }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='medium'
            onClick={handleSubmit}
            sx={{ display: 'inline-block' }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
