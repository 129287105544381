import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Confirm() {
  return (
    <Container>
      <Box sx={{ padding: 2 }}>
        <Typography component='h1' variant='h4'>
          Confirm Account
        </Typography>
        <Typography variant='body1' sx={{ marginTop: 2 }}>
          Please check your email. You will need to confirm your account. Until
          your account is confirmed you will not be able to log in.
        </Typography>
      </Box>
    </Container>
  );
}
