import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/system/Box';
import SiteCard from './components/SiteCard';
import Grid from '@mui/material/Grid';

import SignInModal from '../SignIn/SignInModal';
import SignUpModal from '../SignUp/SignUpModal';
import ForgetPasswordModal from '../ForgetPassword/ForgetPasswordModal';

import acLogo from '../../images/acLogoLogin.png';
import generalFormCard from '../../images/GeneralForms.png';
import employeeFormCard from '../../images/EmployeeForms.png';
import myFormCard from '../../images/MyForms.png';

export default function About({ setTabValue }) {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const handleOpenSignIn = () => setOpenSignIn(true);
  const handleCloseSignIn = () => setOpenSignIn(false);
  const handleOpenSignUp = () => setOpenSignUp(true);
  const handleCloseSignUp = () => setOpenSignUp(false);
  const handleOpenForgetPassword = () => setOpenForgetPassword(true);
  const handleCloseForgetPassword = () => setOpenForgetPassword(false);

  return (
    <>
      <Container>
        <SignInModal
          openSignIn={openSignIn}
          handleCloseSignIn={handleCloseSignIn}
          handleOpenSignUp={handleOpenSignUp}
          handleOpenForgetPassword={handleOpenForgetPassword}
        />
        <SignUpModal
          openSignUp={openSignUp}
          handleCloseSignUp={handleCloseSignUp}
          handleOpenSignIn={handleOpenSignIn}
        />
        <ForgetPasswordModal
          openForgetPassword={openForgetPassword}
          handleCloseForgetPassword={handleCloseForgetPassword}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            component='h2'
            variant='h4'
            align='center'
            sx={{ marginBottom: 2 }}
          >
            Welcome to the Alachua County Form Center
          </Typography>
          <Typography
            variant='body1'
            align='center'
            sx={{ fontSize: '1.2rem' }}
          >
            Welcome to the Alachua County Form Center, your one-stop destination
            for accessing a wide range of citizen services available to the
            public. Whether you're a new user or returning, you can create an
            account or sign in to begin filling out the forms you need.
          </Typography>
          <Button
            variant='contained'
            color='error'
            onClick={handleOpenSignIn}
            sx={{
              alignSelf: 'center',
              borderRadius: 4,
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            Sign in or Create Account
          </Button>
        </Box>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4}>
            <SiteCard
              title='General Forms'
              cardText='View the most frequently requested Alachua County online forms including customer surveys, event registrations, feedback forms, and more.'
              buttonText='Explore Forms'
              setTabValue={setTabValue}
              tabValue='1'
              img={generalFormCard}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SiteCard
              title='Employee Forms'
              cardText='Manage forms related to employee onboarding, benefits enrollment, leave requests, direct deposit, and other HR-related tasks.'
              buttonText='Sign In'
              signIn={handleOpenSignIn}
              setTabValue={setTabValue}
              tabValue='2'
              img={employeeFormCard}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SiteCard
              title='My Forms'
              cardText='The My Forms section is a centralized location where you can manage all your forms.  This includes completing in progress forms and viewing form submissions.'
              buttonText='Sign In'
              signIn={handleOpenSignIn}
              setTabValue={setTabValue}
              tabValue='3'
              img={myFormCard}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
