import React, { useContext, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormsCenterContext } from '../../Context/FormsCenterContext';
import { Typography } from '@mui/material';

export default function ForgetPassword({ ForgetPasswordClose }) {
  const formsObject = useContext(FormsCenterContext);
  const [emailAddress, setEmailAddress] = useState(null);
  const [open, setOpen] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(emailAddress)) {
      formsObject.forgtPasswordSendEmail(emailAddress);
      ForgetPasswordClose();
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          Please enter a valid email.
        </Alert>
      </Snackbar>
      <Link
        href='#'
        underline='none'
        onClick={() => {
          ForgetPasswordClose();
        }}
        sx={{ position: 'absolute', top: 15, right: 15 }}
      >
        X
      </Link>
      <Typography variant='body1'>
        Please enter your email adderess below. If there exists an account
        corresponding to that address, we will send you an email with further
        instruction.
      </Typography>
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
        <TextField
          name='email'
          required
          id='email'
          label='Email Address'
          onChange={(e) => setEmailAddress(e.target.value)}
          sx={{ width: '40%' }}
        />
        <Button type='contained'>Submit</Button>
      </Box>
    </Container>
  );
}
