import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
import BadgeIcon from '@mui/icons-material/Badge';
import Box from '@mui/material/Box';
import BasicMenu from '../BasicMenu';
import { useState } from 'react';

//Images
import acSiteCard from './images/ac-sitecard.png';
import animalResources from './images/animalResources.png';
import budgetFiscalServices from './images/budgetFiscalServices.png';
import communicationsOffice from './images/communicationsOffice.png';
import communitySupportServices from './images/communitySupportServices.png';
import countyAttorney from './images/countyAttorney.png';
import countyCommissioners from './images/countyCommissioners.png';
import courtServices from './images/courtServices.png';
import environmentalProtectionDepartment from './images/environmentalProtectionDepartment.png';
import equalOpportunity from './images/equalOpportunity.png';
import facilitiesManagement from './images/facilitiesManagement.png';
import fireRescue from './images/fireRescue.png';
import growthManagement from './images/growthManagement.png';
import humanResources from './images/humanResources.png';
import informationTelecommincationsServices from './images/informationTelecommunicationsServices.png';
import landConservation from './images/landConservation.png';
import parksOpenSpace from './images/parksOpenSpace.png';
import publicWorks from './images/publicWorks.png';
import riskManagement from './images/riskManagement.png';
import solidWasteResourceRecovery from './images/solidWasteResourceRecovery.png';
import veteransServices from './images/veteranServices.png';

export default function SiteCard({
  department,
  formName,
  formDescription,
  isEmployeeForm,
  lastUpdatedDate,
  formDataId,
  submitted,
  requiresAuth,
}) {
  let departmentName = department;
  departmentName = departmentName.replace(/&amp;/g, '&');
  let departments = departmentName.split(',');
  departments = departments.join(' \u2022 ');

  const [siteCardFormName, setSiteCardFormName] = useState(formName);
  const [siteCardImage, setSiteCardImage] = useState(null);
  const [siteCardColor, setSiteCardColor] = useState('');

  const colors = {
    acDefault: '#1B5B98',
    animalResources: '#1B5B98',
    budgetFiscalServices: '#0F3E57',
    communicationsOffice: '#D05525',
    communitySupportServices: '#52A3E0',
    countyAttorney: '#3F0F57',
    countyCommissioners: '#104356',
    courtServices: '#104356',
    environmentalProtectionDepartment: '#1B5B98',
    equalOpportunity: '#4BB18B',
    facilitiesManagement: '#1B5B98',
    fireRescue: '#9C1C1C',
    growthManagement: '#027EA4',
    humanResources: '#0280A7',
    informationTelecommincationsServices: '#0F5733',
    landConservation: '#22631e',
    parksOpenSpace: '#3C9D7C',
    publicWorks: '#0F573E',
    riskManagement: '#247E9D',
    solidWasteResourceRecovery: '#0F573E',
    veteransServices: '#52A3E0',
  };

  const images = {
    acDefault: acSiteCard,
    animalResources: animalResources,
    budgetFiscalServices: budgetFiscalServices,
    communicationsOffice: communicationsOffice,
    communitySupportServices: communitySupportServices,
    countyAttorney: countyAttorney,
    countyCommissioners: countyCommissioners,
    courtServices: courtServices,
    environmentalProtectionDepartment: environmentalProtectionDepartment,
    equalOpportunity: equalOpportunity,
    facilitiesManagement: facilitiesManagement,
    fireRescue: fireRescue,
    growthManagement: growthManagement,
    humanResources: humanResources,
    informationTelecommincationsServices: informationTelecommincationsServices,
    landConservation: landConservation,
    parksOpenSpace: parksOpenSpace,
    publicWorks: publicWorks,
    riskManagement: riskManagement,
    solidWasteResourceRecovery: solidWasteResourceRecovery,
    veteransServices: veteransServices,
  };

  useEffect(() => {
    switch (department) {
      case 'Animal Resources':
        setSiteCardImage(images.animalResources);
        setSiteCardColor(colors.animalResources);
        break;
      case 'Budget and Fiscal Services':
        setSiteCardImage(images.budgetFiscalServices);
        setSiteCardColor(colors.budgetFiscalServices);
        break;
      // Code Administration'
      case 'Communications Office':
        setSiteCardImage(images.communicationsOffice);
        setSiteCardColor(colors.communicationsOffice);
        break;
      case 'Community Support Services':
        setSiteCardImage(images.communitySupportServices);
        setSiteCardColor(colors.communitySupportServices);
        break;
      case 'County Attorney':
        setSiteCardImage(images.countyAttorney);
        setSiteCardColor(colors.countyAttorney);
        break;
      case 'County Commissioners (Bocc)':
        setSiteCardImage(images.countyCommissioners);
        setSiteCardColor(colors.countyCommissioners);
        break;
      // County Manager's Office
      case 'Court Services':
        setSiteCardImage(images.courtServices);
        setSiteCardColor(colors.courtServices);
        break;
      // Economic Development
      // Emergency Management
      case 'Engineering and Operations':
        setSiteCardImage(images.publicWorks);
        setSiteCardColor(colors.publicWorks);
        break;
      case 'Environmental Protection':
        setSiteCardImage(images.environmentalProtectionDepartment);
        setSiteCardColor(colors.environmentalProtectionDepartment);
        break;
      case 'Equal Opportunity':
        setSiteCardImage(images.equalOpportunity);
        setSiteCardColor(colors.equalOpportunity);
        break;
      case 'Facilities Management':
        setSiteCardImage(images.facilitiesManagement);
        setSiteCardColor(colors.facilitiesManagement);
        break;
      case 'Fire Rescue':
        setSiteCardImage(images.fireRescue);
        setSiteCardColor(colors.fireRescue);
        break;
      case 'Growth Management':
        setSiteCardImage(images.growthManagement);
        setSiteCardColor(colors.growthManagement);
        break;
      case 'Human Resources':
        setSiteCardImage(images.humanResources);
        setSiteCardColor(colors.humanResources);
        break;
      case 'Information & Telecommunications Services':
        setSiteCardImage(images.informationTelecommincationsServices);
        setSiteCardColor(colors.informationTelecommincationsServices);
        break;
      case 'Land Conservation':
        setSiteCardImage(images.landConservation);
        setSiteCardColor(colors.landConservation);
        break;
      case 'Parks & Open Space':
        setSiteCardImage(images.parksOpenSpace);
        setSiteCardColor(colors.parksOpenSpace);
        break;
      case 'Public Works':
        setSiteCardImage(images.publicWorks);
        setSiteCardColor(colors.publicWorks);
        break;
      // Procurement
      case 'Risk Management':
        setSiteCardImage(images.riskManagement);
        setSiteCardColor(colors.riskManagement);
        break;
      case 'Solid Waste & Resource Recovery':
        setSiteCardImage(images.solidWasteResourceRecovery);
        setSiteCardColor(colors.solidWasteResourceRecovery);
        break;
      // Sustainability, Equity, Economic, and Strategic Development
      case 'Veteran Services':
        setSiteCardImage(images.veteransServices);
        setSiteCardColor(colors.veteransServices);
        break;
      // Visitors and Convention Bureau
      default:
        setSiteCardImage(images.acDefault);
        setSiteCardColor(colors.acDefault);
        break;
    }
  }, []);

  return (
    <Card
      elevation={3}
      sx={{
        maxWidth: '450px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        height: '100%',
      }}
    >
      <Box bgcolor={siteCardColor}>
        <CardMedia
          component='img'
          height='140'
          image={siteCardImage}
          alt='Alachua County Site Card'
          sx={{
            width: 'initial',
          }}
        />
      </Box>
      <CardContent>
        {lastUpdatedDate && (
          <Box display='flex' justifyContent='flex-end'>
            <BasicMenu
              formDataId={formDataId}
              formName={siteCardFormName}
              setFormName={setSiteCardFormName}
            />
          </Box>
        )}
        {isEmployeeForm && (
          <Avatar
            sx={{
              bgcolor: yellow[600],
              width: 24,
              height: 24,
              marginBottom: 2,
            }}
            aria-label='recipe'
          >
            <BadgeIcon />
          </Avatar>
        )}
        <Typography variant='body1' color='text.secondary'>
          {departments}
        </Typography>
        <Typography gutterBottom variant='h5' component='div'>
          {siteCardFormName}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {formDescription}
          {lastUpdatedDate && (
            <p>
              {submitted ? 'Submitted On' : 'Last Updated On'}:{' '}
              {new Date(lastUpdatedDate).toLocaleDateString('en-US')}
            </p>
          )}
        </Typography>
      </CardContent>
      {!isEmployeeForm && requiresAuth && (
        <Typography variant='body1' sx={{ ml: 2 }} gutterBottom>
          *Login Required
        </Typography>
      )}
    </Card>
  );
}
