import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import SignIn from './SignIn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SignInModal({
  openSignIn,
  handleCloseSignIn,
  handleOpenSignUp,
  handleOpenForgetPassword,
}) {
  return (
    <Modal
      open={openSignIn}
      onClose={handleCloseSignIn}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          ...style,
          width: { xs: '100%', sm: '85%', md: '75%', lg: '50%' },
        }}
      >
        <SignIn
          SignInClose={handleCloseSignIn}
          SignUpOpen={handleOpenSignUp}
          ForgetPasswordOpen={handleOpenForgetPassword}
        />
      </Box>
    </Modal>
  );
}
