import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RenameFormModal from './RenameForm/RenameFormModal';

export default function BasicMenu({ formDataId, formName, setFormName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRenameForm, setOpenRenameForm] = useState(false);

  const open = Boolean(anchorEl);

  const handleClickOpenMenu = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClickCloseMenu = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleClickOpenRenameForm = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setOpenRenameForm(true);
  };

  const handleClickCloseRenameForm = (e) => {
    e.preventDefault();
    setOpenRenameForm(false);
  };

  return (
    <>
      <RenameFormModal
        formDataId={formDataId}
        formName={formName}
        setFormName={setFormName}
        openRenameForm={openRenameForm}
        handleCloseRenameForm={handleClickCloseRenameForm}
      />
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpenMenu}
      >
        ...
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClickOpenRenameForm}>Rename Form</MenuItem>
      </Menu>
    </>
  );
}
