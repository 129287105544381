import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

export default function SiteCard({
  title,
  cardText,
  buttonText,
  setTabValue,
  tabValue,
  img,
}) {
  const handleSetTabVaue = () => {
    console.log('tabValue', tabValue);
    console.log('typeof tabValue', typeof tabValue);
    setTabValue(parseInt(tabValue));
  };
  return (
    <Card
      elevation={3}
      sx={{
        maxWidth: '450px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          fontSize: '1rem',
          color: '#FFF',
          textTransform: 'uppercase',
          fontWeight: 500,
          letterSpacing: '1.2px',
          textAlign: 'center',
        }}
        sx={{
          padding: '16px 16px',
          backgroundColor: '#3D86C1',
        }}
      />
      <CardContent>
        <CardMedia sx={{ height: 140, mb: 2 }} image={img} title={title} />
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            color: '#212121',
          }}
        >
          {cardText}
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          color='error'
          onClick={handleSetTabVaue}
          sx={{ borderRadius: 4 }}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
}
