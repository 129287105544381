import React, { useState, useContext } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

import Dashboard from './Branding/Dashboard';
import Home from './components/Home';
import Confirm from './components/ConfirmPage';
import ResetPassword from './components/ResetPassword';
import MicroFrontend from './MicroFrontend';
import SignIn from './components/SignIn/SignIn';

import { FormsCenterContext } from './Context/FormsCenterContext';

import { CircularProgress } from '@mui/material';

function RestrictedRoute() {
  const formsObject = useContext(FormsCenterContext);
  const history = useHistory();

  return (
    <>
      <Button
        variant='text'
        startIcon={<HomeIcon />}
        onClick={() => {
          history.push('/');
          formsObject.setCrumbs([]);
        }}
      >
        Home
      </Button>
      <SignIn RequiresAuth={true} />
    </>
  );
}

// Requires user to be authenticated in order to gain access to MFE
function RenderMicroFrontendAuthEmployee({
  isAuth,
  mfeUrl,
  mfeEndPoint,
  mfeName,
  passportUrl,
  containerUrl,
}) {
  const { search, pathname } = useLocation();

  if (!isAuth) {
    window.location.href = `${passportUrl}/adfs?redirectUrl=${containerUrl}${
      search ? search : ''
    }`;
  } else {
    return <MicroFrontend host={`${mfeUrl}/${mfeEndPoint}/`} name={mfeName} />;
  }
}

// Requires user to be authenticated in order to gain access to MFE
function RenderMicroFrontendAuthGeneral({
  isAuth,
  mfeUrl,
  mfeEndPoint,
  mfeName,
}) {
  if (!isAuth) {
    return <RestrictedRoute />;
  }
  return <MicroFrontend host={`${mfeUrl}/${mfeEndPoint}/`} name={mfeName} />;
}

// Grants access to MFE without user being authenticated
function RenderMicroFrontend({ mfeUrl, mfeEndPoint, mfeName }) {
  return <MicroFrontend host={`${mfeUrl}/${mfeEndPoint}/`} name={mfeName} />;
}

// Returns an iframe containing url of passed in form
// Used to render forms from Survey Monkey, Seamless Docs, etcetera.
function RenderIFrame({ url, title }) {
  const formsObject = useContext(FormsCenterContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const styles = {
    spinnerImage: {
      height: '100vh',
    },
  };

  return (
    <main style={styles.spinnerImage}>
      <Button
        variant='text'
        startIcon={<HomeIcon />}
        onClick={() => {
          history.push('/');
          formsObject.setCrumbs([]);
        }}
      >
        Home
      </Button>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : null}
      <iframe
        src={url}
        title={title}
        width='100%'
        height='95%'
        allow='geolocation'
        onLoad={() => setLoading(false)}
      ></iframe>
    </main>
  );
}

// Returns an iframe containing url of passed in form
// Used to render forms from Survey Monkey, Seamless Docs, etcetera.
function RenderIFrameAuth({ isAuth, url, title }) {
  const formsObject = useContext(FormsCenterContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const styles = {
    spinnerImage: {
      height: '100vh',
    },
  };

  if (!isAuth) {
    return <RestrictedRoute />;
  }
  return (
    <main style={styles.spinnerImage}>
      <Button
        variant='text'
        startIcon={<HomeIcon />}
        onClick={() => {
          history.push('/');
          formsObject.setCrumbs([]);
        }}
      >
        Home
      </Button>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : null}
      <iframe
        src={url}
        title={title}
        width='100%'
        height='100%'
        allow='geolocation'
        onLoad={() => setLoading(false)}
      ></iframe>
    </main>
  );
}

// Dynamically renders React Router DOM Route components
function RenderRoutes(formsObject) {
  // Routes for 'General Forms'
  const generalForms = [
    ...formsObject.generalForms,
    ...formsObject.generalFormsExternal,
  ].map((form) => {
    // Forms developed as micro frontend(s) by ITS
    // General forms that require users to authenticate via username and password
    if (form.RequiresAuth) {
      // External forms such as Survey Monkey, Seamless Docs, etcetera wil have isExternal flag set to true
      if (form.IsExternal) {
        return (
          <Route
            path={`/${form.EndPoint}/*`}
            render={() => (
              <RenderIFrameAuth
                isAuth={formsObject.isAuthLocal || formsObject.isAuthSAML}
                url={form.ExternalUrl}
                title={form.FormName}
              />
            )}
          />
        );
      }

      if (!form.IsExternal) {
        return (
          <Route
            path={`/${form.EndPoint}/*`}
            render={() => (
              <RenderMicroFrontendAuthGeneral
                isAuth={formsObject.isAuthLocal || formsObject.isAuthSAML}
                mfeUrl={formsObject.mfeUrl}
                mfeEndPoint={form.EndPoint}
                mfeName={form.MicroFrontEndName}
                passportUrl={formsObject.passportUrlSAML}
                containerUrl={`${formsObject.containerUrl}/${form.EndPoint}/`}
              />
            )}
          />
        );
      }
    }

    if (!form.RequiresAuth) {
      if (form.IsExternal) {
        return (
          <Route
            path={`/${form.EndPoint}/*`}
            render={() => (
              <RenderIFrame
                isAuth={formsObject.isAuthLocal || formsObject.isAuthSAML}
                url={form.ExternalUrl}
                title={form.FormName}
              />
            )}
          />
        );
      }

      if (!form.IsExternal) {
        return (
          <Route
            path={`/${form.EndPoint}/*`}
            render={() => (
              <RenderMicroFrontend
                mfeUrl={formsObject.mfeUrl}
                mfeEndPoint={form.EndPoint}
                mfeName={form.MicroFrontEndName}
              />
            )}
          />
        );
      }
    }
  });

  // Routes for 'Employee Forms'
  const employeeForms = [
    ...formsObject.employeeForms,
    ...formsObject.employeeFormsExternal,
  ].map((form) => {
    // External forms such as Survey Monkey, Seamless Docs, etc. wil have isExternal flag set to true
    if (form.IsExternal) {
      return (
        <Route
          path={`/${form.EndPoint}/*`}
          render={() => (
            <RenderIFrameAuth
              isAuth={formsObject.isAuthLocal || formsObject.isAuthSAML}
              url={form.ExternalUrl}
              title={form.FormName}
            />
          )}
        />
      );
    }
    // Employee forms that require users to authenticate via ADFS
    if (!form.IsExternal) {
      return (
        <Route
          path={`/${form.EndPoint}/*`}
          render={() => (
            <RenderMicroFrontendAuthEmployee
              isAuth={formsObject.isAuthSAML}
              mfeUrl={formsObject.mfeUrl}
              mfeEndPoint={form.EndPoint}
              mfeName={form.MicroFrontEndName}
              passportUrl={formsObject.passportUrlSAML}
              containerUrl={`${formsObject.containerUrl}/${form.EndPoint}/`}
            />
          )}
        />
      );
    }
  });

  // Routes for 'In Progress Forms'
  const inProgressForms = formsObject.inProgressForms.map((form) => {
    return (
      <Route
        path={`/${form.EndPoint}/*`}
        render={() => (
          <RenderMicroFrontend
            mfeUrl={formsObject.mfeUrl}
            mfeEndPoint={form.EndPoint}
            mfeName={form.MicroFrontEndName}
          />
        )}
      />
    );
  });

  // Routes for 'In Progress Forms Employee'
  const inProgressFormsEmp = formsObject.inProgressFormsEmployee.map((form) => {
    return (
      <Route
        path={`/${form.EndPoint}/*`}
        render={() => (
          <RenderMicroFrontend
            mfeUrl={formsObject.mfeUrl}
            mfeEndPoint={form.EndPoint}
            mfeName={form.MicroFrontEndName}
          />
        )}
      />
    );
  });

  // Routes for 'Submitted Forms'
  const submittedForms = formsObject.submittedForms.map((form) => {
    return (
      <Route
        path={`/${form.EndPoint}/*`}
        render={() => (
          <RenderMicroFrontend
            mfeUrl={formsObject.mfeUrl}
            mfeEndPoint={form.EndPoint}
            mfeName={form.MicroFrontEndName}
          />
        )}
      />
    );
  });

  // Routes for 'Submitted Forms Employee'
  const submittedFormsEmp = formsObject.submittedFormsEmployee.map((form) => {
    return (
      <Route
        path={`/${form.EndPoint}/*`}
        render={() => (
          <RenderMicroFrontend
            mfeUrl={formsObject.mfeUrl}
            mfeEndPoint={form.EndPoint}
            mfeName={form.MicroFrontEndName}
          />
        )}
      />
    );
  });

  const routes = [
    ...generalForms,
    ...employeeForms,
    ...inProgressForms,
    ...inProgressFormsEmp,
    ...submittedForms,
    ...submittedFormsEmp,
  ];

  return routes;
}

function App() {
  const formsObject = useContext(FormsCenterContext);
  return formsObject.isLoading ? (
    <p>Loading...</p>
  ) : (
    <BrowserRouter>
      {/* Dashboard is somewhat equivalent to a Master Page */}
      <Dashboard>
        <Switch>
          <Route path='/resetPassword' component={ResetPassword} />
          <Route path='/confirm' component={Confirm} />
          {/* Dynamically render React Router DOM Route components */}
          {!formsObject.isLoading && RenderRoutes(formsObject)}
          <Route path='/' component={Home} />
        </Switch>
      </Dashboard>
    </BrowserRouter>
  );
}

export default App;
