import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SiteCard from './SiteCard/SiteCard';
import SignInModal from './SignIn/SignInModal';
import SignUpModal from './SignUp/SignUpModal';
import ForgetPasswordModal from './ForgetPassword/ForgetPasswordModal';
import { FormsCenterContext } from '../Context/FormsCenterContext';
import { TextField } from '@mui/material';

export default function GeneralForms() {
  const formsObject = useContext(FormsCenterContext);
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredForms, setFilteredForms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const handleOpenSignIn = () => setOpenSignIn(true);
  const handleCloseSignIn = () => setOpenSignIn(false);
  const handleOpenSignUp = () => setOpenSignUp(true);
  const handleCloseSignUp = () => setOpenSignUp(false);
  const handleOpenForgetPassword = () => setOpenForgetPassword(true);
  const handleCloseForgetPassword = () => setOpenForgetPassword(false);

  // Logs user out of application
  const logout = async () => {
    // If user is authenticated via ADFS
    if (formsObject.isAuthSAML) {
      // removes authentication cookie from browser
      await axios.get(`${formsObject.passportUrlSAML}/logout`, {
        withCredentials: true,
      });

      await formsObject.logout();
    }

    // If user is authenticated via username/password - Passport Local
    if (formsObject.isAuthLocal) {
      // removes authentication cookie from browser
      await axios.get(`${formsObject.passportUrlLocal}/logout`, {
        withCredentials: true,
      });

      await formsObject.logout();
      window.location.reload();
    }
  };

  // Event handler for filter dropdown
  const handleFilterChange = (event) => {
    setFilter(event.target.value);

    if (event.target.value === 'All Forms') {
      // All forms
      let generalForms = [
        ...formsObject.generalForms,
        ...formsObject.generalFormsExternal,
      ];

      setFilteredForms(generalForms);
    } else {
      // Department
      let generalForms = [
        ...formsObject.generalForms,
        ...formsObject.generalFormsExternal,
      ];
      let filtered = generalForms.filter(function (form) {
        return form.Departments.includes(event.target.value);
      });
      // Set filteredForms state array
      setFilteredForms(filtered);
    }
  };

  // Event handler for sort dropdown
  const handleSortChange = (event) => {
    setSort(event.target.value);

    let filtered;

    // Sort by date ASC
    if (event.target.value === 'Oldest') {
      filtered = filteredForms.sort((a, b) => {
        if (a.DateAdded < b.DateAdded) {
          return -1;
        }
        if (a.DateAdded > b.DateAdded) {
          return 1;
        }
        return 0;
      });
    }

    // Sort by date DESC
    if (event.target.value === 'Newest') {
      filtered = filteredForms.sort((a, b) => {
        if (a.DateAdded > b.DateAdded) {
          return -1;
        }
        if (a.DateAdded < b.DateAdded) {
          return 1;
        }
        return 0;
      });
    }

    // Sort by Form Name
    if (event.target.value === 'Form Name') {
      filtered = filteredForms.sort((a, b) => {
        if (a.FormName < b.FormName) {
          return -1;
        }
        if (a.FormName > b.FormName) {
          return 1;
        }
        return 0;
      });
    }
    setFilteredForms(filtered);
  };

  //fires when formsObject is set
  useEffect(() => {
    const deptSet = new Set();

    let generalForms = [
      ...formsObject.generalForms,
      ...formsObject.generalFormsExternal,
    ];

    setFilteredForms(generalForms);

    // Iterate over all forms in order to build a unique set of departments
    for (let form of generalForms) {
      // Get departments for current form
      let departments = form.Departments;
      // Departments is stored in DB as a comma delimited string
      // Convert comma delimited string to array
      let departmentArr = departments.split(',');
      // Iterate over items in array and add to set
      // Items in set will be unique
      for (let departmentName of departmentArr) {
        deptSet.add(departmentName);
      }
    }
    // Convert set to array and sort in ASC order
    setDepartments(Array.from(deptSet).sort());
  }, [formsObject]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 3,
        }}
      >
        {/* Show if user IS NOT authenticated */}
        {!formsObject.isAuthSAML && !formsObject.isAuthLocal && (
          <>
            <Button
              onClick={handleOpenSignIn}
              sx={{ textDecoration: 'underline' }}
            >
              Login or Sign Up
            </Button>
            <SignInModal
              openSignIn={openSignIn}
              handleCloseSignIn={handleCloseSignIn}
              handleOpenSignUp={handleOpenSignUp}
              handleOpenForgetPassword={handleOpenForgetPassword}
            />
            <SignUpModal
              openSignUp={openSignUp}
              handleCloseSignUp={handleCloseSignUp}
              handleOpenSignIn={handleOpenSignIn}
            />
            <ForgetPasswordModal
              openForgetPassword={openForgetPassword}
              handleCloseForgetPassword={handleCloseForgetPassword}
            />
          </>
        )}
        {/* Show if user IS authenticated */}
        {(formsObject.isAuthSAML || formsObject.isAuthLocal) && (
          <>
            <Button variant='outlined' onClick={() => logout()}>
              Logout
            </Button>
          </>
        )}
      </Box>

      {/* <Grid
        container
        sx={{
          marginBottom: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: 2,
            justifyContent: { xs: 'center', md: 'flex-start' },
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '200px',
              display: 'flex',
              paddingRight: '10px',
            }}
          >
            <TextField
              value={filter}
              onChange={handleFilterChange}
              label='Filter By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'All Forms'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                All Forms
              </MenuItem>

              {departments.map((department, index) => (
                <MenuItem
                  key={index}
                  value={department}
                  sx={{
                    fontSize: '.9rem',
                  }}
                >
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ width: '100%', maxWidth: '175px', display: 'flex' }}>
            <TextField
              value={sort}
              onChange={handleSortChange}
              label='Sort By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'Newest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Newest
              </MenuItem>
              <MenuItem
                value={'Oldest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Oldest
              </MenuItem>
              <MenuItem
                value={'Form Name'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Form Name
              </MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        {filteredForms.length > 0 ? (
          // Renders SiteCard/Form for each item in filteredForms
          filteredForms.map((form, index) => (
            // Renders link for external forms - Survey Monkey, Seamless Docs, etcetera
            <React.Fragment key={index}>
              <Grid item xs={12} md={6} lg={4}>
                <Link
                  to={`/${form.EndPoint}/`}
                  style={{ textDecoration: 'none' }}
                >
                  <SiteCard
                    department={form.Departments}
                    formName={form.FormName}
                    formDescription={form.FormDescription}
                    isEmployeeForm={form.IsEmployeeForm}
                    requiresAuth={form.RequiresAuth}
                  />
                </Link>
              </Grid>
            </React.Fragment>
          ))
        ) : (
          // Displays message if there are no forms to display
          <Grid item xs={12}>
            <Typography variant='body1'>
              There are currently no <b>General Forms</b> available.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
