import React, { useContext, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormsCenterContext } from '../../Context/FormsCenterContext';
import { useLocation } from 'react-router-dom';

import acLogoLogin from '../../images/acLogoLogin.png';

const theme = createTheme();

export default function SignIn({
  SignInClose,
  SignUpOpen,
  ForgetPasswordOpen,
  RequiresAuth,
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const { pathname, search } = useLocation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'POST',
      data: {
        username,
        password,
      },
      url: `${formsObject.passportUrlLocal}/login`,
      withCredentials: true,
    }).then((res) => {
      if (res.data === 'Authenticated') {
        window.location.href = '/';
      } else {
        setMessage(res.data.toUpperCase());
        setOpen(true);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formsObject = useContext(FormsCenterContext);

  console.log(
    `${formsObject.passportUrlSAML}/adfs?redirectUrl=${formsObject.containerUrl}${pathname}`
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='md'>
        <CssBaseline />
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link
            href='#'
            underline='none'
            onClick={() => SignInClose()}
            sx={{ position: 'absolute', top: 15, right: 15 }}
          >
            X
          </Link>
          {RequiresAuth && <p>Login is required to access this form.</p>}
          <img
            src={acLogoLogin}
            alt='Alachua County Logo'
            style={{
              zIndex: '100',
              position: 'relative',
            }}
          />
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              name='email'
              required
              fullWidth
              id='email'
              label='Email Address'
              onChange={(e) => setUserName(e.target.value)}
            />
            <FormControl margin='normal' variant='outlined' fullWidth>
              <InputLabel htmlFor='outlined-adornment-password'>
                Password
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
                name='password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Box>
                <Link
                  href='#'
                  variant='body2'
                  onClick={() => {
                    SignInClose();
                    SignUpOpen();
                  }}
                >
                  Create Account
                </Link>
              </Box>
              {' | '}
              <Box>
                <Link
                  href='#'
                  variant='body2'
                  onClick={() => {
                    SignInClose();
                    ForgetPasswordOpen();
                  }}
                >
                  Forgot Password?
                </Link>
              </Box>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}
            >
              <Link
                onClick={() => {
                  window.location.href = `${
                    formsObject.passportUrlSAML
                  }/adfs?redirectUrl=${formsObject.containerUrl}${pathname}${
                    search ? search : ''
                  }`;
                }}
                variant='body2'
                href='#'
              >
                Alachua County Employees - Login Here to View Employee Forms
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
