import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormsCenterContext } from '../Context/FormsCenterContext';
import queryString from 'query-string';

export default function Confirm() {
  const formsObject = useContext(FormsCenterContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMismatchOpen, setPasswordMismatchOpen] = useState(false);
  const [passwordUpdateOpen, setPasswordUpdateOpen] = useState(false);

  const history = useHistory();

  const { search } = useLocation();
  const { userId } = queryString.parse(search);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((showConfirm) => !showConfirm);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  const handlePasswordMismatchClose = () => {
    setPasswordMismatchOpen(false);
  };

  const handlePasswordUpdateClose = () => {
    setPasswordUpdateOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      setPasswordMismatchOpen(true);
    } else {
      axios({
        method: 'PUT',
        data: {
          userId,
          password,
        },
        url: `${formsObject.passportUrlLocal}/resetPassword`,
      })
        .then((res) => {
          console.log('RESULT', res);
          setPasswordUpdateOpen(true);
          setTimeout(() => {
            history.push('/');
          }, 2000);
        })
        .catch((err) => {
          console.log('ERROR', err);
        });
    }
  };

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Snackbar
          open={passwordUpdateOpen}
          autoHideDuration={3000}
          onClose={handlePasswordUpdateClose}
        >
          <Alert
            onClose={handlePasswordUpdateClose}
            severity='success'
            sx={{ width: '100%' }}
          >
            PASSWORD UPDATED
          </Alert>
        </Snackbar>

        <Snackbar
          open={passwordMismatchOpen}
          autoHideDuration={3000}
          onClose={handlePasswordMismatchClose}
        >
          <Alert
            onClose={handlePasswordMismatchClose}
            severity='error'
            sx={{ width: '100%' }}
          >
            PASSWORDS DO NOT MATCH
          </Alert>
        </Snackbar>

        <Typography component='h1' variant='h5'>
          Reset Password
        </Typography>

        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl margin='normal' variant='outlined' fullWidth>
                <InputLabel
                  htmlFor='outlined-adornment-password'
                  variant='filled'
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ paddingTop: '15px' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin='normal' variant='outlined' fullWidth>
                <InputLabel
                  htmlFor='outlined-adornment-password-confirm'
                  variant='filled'
                >
                  Re-Enter Password
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password-confirm'
                  type={showPasswordConfirm ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                        edge='end'
                      >
                        {showPasswordConfirm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  sx={{ paddingTop: '15px' }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
                RESET
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
