import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faFlickr,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const SocialButton = styled(Button)(({ theme }) => ({
  background: 'rgba(0,0,0,0.7)',
  color: 'white',
  fontSize: '1.8rem',
  border: '3px solid white',
  borderRadius: '1000px',
  padding: '1rem',
  margin: 'auto',
}));

const SocialLink = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  textDecoration: 'underline',
}));

export default function Footer() {
  return (
    <>
      <Box
        sx={{
          color: 'white',
          padding: '1rem',
          textAlign: 'center',
          fontSize: '0.85rem',
          background: { xs: '#001329', md: 'rgba(0,0,0,0.65)' },
          marginTop: { xs: '0', sm: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <p>
          Alachua County aims to continually improve the accessibility and
          usability of its website. If you are an individual with a disability
          and you experience difficulty or require assistance or accommodation
          in using our website, please contact the Alachua County ADA
          Coordinator at ADA@alachuacounty.us or call the Alachua County Equal
          Opportunity Office at 352-374-5275; TDD/TTY Users please call 711
          Florida Relay Service.
        </p>
        <Button
          variant='contained'
          sx={{
            borderRadius: '1000px',
            background: '#13467a',
            textTransform: 'none',
            py: '1rem',
            px: '3rem',
            textDecoration: 'underline',
          }}
        >
          View Alachua County's Website Accessibility Policy And Procedures
        </Button>
      </Box>

      <Box
        sx={{
          color: 'white',
          padding: '1rem',
          fontSize: '0.85rem',
          background: { xs: '#001329', md: 'rgba(0,0,0,0.65)' },
          marginTop: { xs: '0', md: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <p>
          If you have a disability and need an accommodation in order to
          participate in a County program, service or public meeting, please
          contact the Equal Opportunity Office at 352-374-5275 at least 2
          business days prior to the event. TDD users, please call 711 (Florida
          Relay Service).
        </p>
      </Box>

      <Box
        sx={{
          color: { xs: 'black', md: 'white' },
          padding: '1rem',
          fontSize: '0.85rem',
          background: { xs: 'white', sm: 'none' },
          marginTop: { xs: '0', md: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                aria-label='Facebook Icon Button'
                  href='https://www.facebook.com/AlachuaCounty/'
                  sx={{
                    '&:hover': {
                      background: '#0072BC',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='https://www.facebook.com/AlachuaCounty/'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Facebook
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                  aria-label='Instagram Icon Button'
                  href='https://www.instagram.com/alachuacounty/'
                  sx={{
                    '&:hover': {
                      // background: '#400704',
                      background: 'rgb(131,58,180)',
                      //background:'linear-gradient(320deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='https://www.instagram.com/alachuacounty/'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Instagram
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                  aria-label='Twitter Icon Button'
                  href='https://twitter.com/alachuacounty'
                  sx={{
                    '&:hover': {
                      background: '#55a4d5',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='https://twitter.com/alachuacounty'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Twitter
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                  aria-label='YouTube Icon Button'
                  href='#'
                  sx={{
                    '&:hover': {
                      background: '#b32d1b',
                    },
                    fontSize: '1.5rem',
                  }}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='#'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Youtube
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                  aria-label='Flickr Icon Button'
                  href='https://www.flickr.com/photos/66143513@N03/'
                  sx={{
                    '&:hover': {
                      background: 'rgb(255,0,179)',
                      //background: 'linear-gradient(85deg, rgba(255,0,179,1) 0%, rgba(0,153,255,1) 100%)',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faFlickr} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='https://www.flickr.com/photos/66143513@N03/'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Flickr
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            md={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                <SocialButton
                  aria-label='Contact Us Icon Button'
                  href='https://alachuacounty.us/ContactUs/Pages/contactus.aspx'
                  sx={{
                    '&:hover': {
                      background: '#ed7e39',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </SocialButton>
              </Grid>
              <Grid item xs={12}>
                <SocialLink
                  fullWidth
                  href='https://alachuacounty.us/ContactUs/Pages/contactus.aspx'
                  sx={{ color: { xs: 'black', sm: 'white' } }}
                >
                  Contact Us
                </SocialLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          color: 'white',
          padding: '1rem',
          fontSize: '0.85rem',
          background: { xs: '#12253d', md: 'rgba(0,0,0,0.65)' },
          marginTop: { xs: '0', md: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <p>
          To make a public records request, please contact the Public Records
          Custodian at (352) 264-6906 or{' '}
          <a
            href='mailto:publicrecordsrequest@alachuacounty.us'
            style={{ color: 'white' }}
          >
            publicrecordsrequest@alachuacounty.us
          </a>
          . Please visit the{' '}
          <a
            href='https://alachuacounty.us/Depts/Communications/Pages/PublicRecordsRequest.aspx'
            style={{ color: 'white' }}
          >
            Public Records Request
          </a>{' '}
          webpage for more information.
        </p>
        <p>
          This website is a public service. Please read the Legal Disclaimer.
          Website designed and engineered by Alachua County ITS, Applications
          Division. Under Florida law (Statute 119.011), all information,
          including e-mail, written letters, documents and phone messages, sent
          to the Alachua County Board of County Commissioners is subject to
          Public Records law. This includes the sender's e-mail address, home
          address or phone number if shown in the message, the content of the
          message and any associated attachments to the mail. Also please be
          aware that electronic correspondence (e-mail) is made available on the
          Commission's public archive site immediately upon being sent. Instead,
          contact Alachua County Offices by phone or in writing.
        </p>
        <p>
          Under Florida law, e-mail addresses are public records. If you do not
          want your e-mail address released in response to a public records
          request, do not send electronic mail to this entity. Instead, contact
          this office by phone or in writing.
        </p>
      </Box>

      <Box
        sx={{
          color: 'white',
          padding: '1rem',
          fontSize: '0.85rem',
          background: { xs: '#12253d', md: 'rgba(0,0,0,0.65)' },
          marginTop: { xs: '0', md: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <p>
          As part of an ongoing accessibility initiative we are using the
          Siteimprove Intelligence Platform TM to help identify and prioritize
          accessibility issues on our website.
        </p>
      </Box>

      <Box
        sx={{
          color: 'white',
          padding: '1rem',
          fontSize: '0.85rem',
          background: { xs: '#12253d', md: 'rgba(0,0,0,0.65)' },
          marginTop: { xs: '0', md: '1rem' },
          marginBottom: { xs: '0', sm: '1rem' },
          borderRadius: { xs: '0', sm: '8px' },
        }}
      >
        <p>
          Background image provided by:{' '}
          <a href='https://phototalestudio.com/' style={{ color: 'white' }}>
            PhotoTale Studio, Portrait, Family and Landscape Photographer in
            Gainesville, FL
          </a>
        </p>
      </Box>
    </>
  );
}
