import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import Nav from './Nav';
import Breadcrumbs from './Breadcrumbs';
import Footer from './Footer';

import bg1 from '../images/bg1.jpeg';
import acLogo from '../images/acLogo.png';
import acLogoMobile from '../images/acLogo-mobile.png';

export default function Dashboard({ children }) {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        sx={{
          backgroundImage: `url(${bg1})`,
          padding: '5px',
        }}
      >
        <CssBaseline />
        {/* AC Logo - Medium*/}
        <Grid item xs={1} xl={2}></Grid>
        <Grid item xs={10} xl={8}>
          <Container
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            <img
              src={acLogo}
              alt='Alachua County Logo'
              style={{
                marginBottom: '-0.7rem ',
                zIndex: '100',
                position: 'relative',
              }}
            />
          </Container>
        </Grid>
        <Grid item xs={1} xl={2}></Grid>

        {/* Navigation */}
        <Nav />

        {/* AC Logo - Mobile */}
        <Grid
          item
          xs={0}
          sm={1}
          xl={2}
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          xl={8}
          sx={{
            display: { xs: 'flex', md: 'none' },
            textAlign: 'center',
            padding: '1rem',
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              flexGrow: 1,
            }}
          >
            <img
              src={acLogoMobile}
              alt='Alachua County Logo (mobile version)'
              style={{ margin: '0.5rem' }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={1}
          xl={2}
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        ></Grid>

        {/* Breadcrumbs */}
        <Grid
          item
          xs={0}
          sm={1}
          xl={2}
          sx={{
            display: 'flex',
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          xl={8}
          sx={{
            display: 'flex',
          }}
        >
          <Breadcrumbs />
        </Grid>
        <Grid
          item
          xs={0}
          sm={1}
          xl={2}
          sx={{
            display: 'flex',
          }}
        ></Grid>

        {/* Main */}
        <Grid item xs={0} sm={1} xl={2}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          xl={8}
          sx={{
            background: '#fff',
            border: { xs: '0', sm: '8px black solid' },
            borderRadius: { xs: '0', sm: '0px 0px 10px 10px', md: '10px' },
          }}
        >
          {children}
        </Grid>
        <Grid item xs={0} sm={1} xl={2}></Grid>

        {/* Footer */}
        <Grid item xs={0} sm={1} xl={2}></Grid>
        <Grid item xs={12} sm={10} xl={8}>
          <Footer />
        </Grid>
        <Grid item xs={0} sm={1} xl={2}></Grid>
      </Grid>
    </>
  );
}
