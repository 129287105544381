import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from '@mui/material';

import { FormsCenterContext } from '../Context/FormsCenterContext';
import { useLocation } from 'react-router-dom';

export default function Breadcrumbs() {
  const formsObject = useContext(FormsCenterContext);
  const location = useLocation();

  // Get population of all forms for logged in user
  const forms = [
    ...formsObject.generalForms,
    ...formsObject.generalFormsExternal,
    ...formsObject.employeeForms,
    ...formsObject.employeeFormsExternal,
    ...formsObject.inProgressForms,
    ...formsObject.inProgressFormsEmployee,
    ...formsObject.submittedForms,
    ...formsObject.submittedFormsEmployee,
  ];

  useEffect(() => {
    for (const form of forms) {
      // URL matches form 'EndPoint' render breadcrumb
      if (location.pathname === `/${form.EndPoint}/`) {
        formsObject.setCrumbs([
          { id: location.pathname, name: `${form.FormName}` },
        ]);
        break;
      }
    }
  }, [location]);

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: {
          xs: 'none',
          sm: '8px 8px 0px 0px',
          md: '0px 0px 8px 8px',
        },
        background: 'rgba(0,0,0,0.5)',
        marginBottom: { xs: 'none', md: '1rem' },
        pl: '2rem',
        color: 'white',
        minHeight: '35px',
      }}
    >
      <Link
        href='https://alachuacounty.us/'
        variant='caption'
        aria-label='Home Icon Link'
      >
        <HomeIcon
          fontSize='small'
          sx={{
            color: 'white',
            marginTop: '0.25rem',
            paddingTop: '0.25rem',
            borderBottom: '1px solid',
          }}
        />
      </Link>

      <Box sx={{ ml: '0.5rem' }} component='span'>
        <Typography variant='caption'>▸</Typography>
      </Box>

      <Link
        href='https://alachuacounty.us/Depts/Pages/Depts.aspx'
        sx={{
          color: 'white',
          textDecoration: 'underline',
          ml: '0.5rem',
          fontSize: '0.75rem',
        }}
      >
        County Offices
      </Link>

      <Box sx={{ ml: '0.5rem' }} component='span'>
        <Typography variant='caption'>▸</Typography>
      </Box>

      <Link
        href={`${formsObject.containerUrl}`}
        sx={{
          color: 'white',
          textDecoration: 'underline',
          ml: '0.5rem',
          fontSize: '0.75rem',
        }}
      >
        Alachua County Forms
      </Link>

      {formsObject.crumbs.length > 0 &&
        formsObject.crumbs.map((page, index) => (
          <span key={index}>
            <Box sx={{ ml: '0.5rem' }} component='span'>
              <Typography variant='caption'>▸</Typography>
            </Box>

            <Link
              href={`${formsObject.containerUrl}${page.id}`}
              underline='always'
              sx={{
                color:
                  index === formsObject.crumbs.length - 1 ? '#FF6200' : 'white',
                fontWeight: index === formsObject.crumbs.length - 1 && 'bold',
                textDecoration:
                  index !== formsObject.crumbs.length - 1 && 'underline',
                ml: '0.5rem',
                fontSize: '0.75rem',
                display: 'inline-flex',
              }}
            >
              {page.name}
            </Link>
          </span>
        ))}
    </Box>
  );
}
