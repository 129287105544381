import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';

const NavButton = styled(Button)(({ theme }) => ({
  background: '#610000',
  color: 'white',
  textTransform: 'none',
  textDecoration: 'underline',
  borderRadius: 0,
  borderLeft: '1px solid #330000',

  '&:hover': {
    background: `#400704`,
  },
}));

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        sx={{
          background: { xs: '#001329', md: '#610000' },
          boxShadow: '0 1px 2px #000',
          color: '#fff',
        }}
      >
        <CssBaseline />
        <Grid item xs={0} sm={1} xl={2}></Grid>
        <Grid item xs={12} sm={10} xl={8}>
          <Toolbar disableGutters sx={{ minHeight: '0 !important' }}>
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                minHeight: '4rem',
              }}
            >
              <IconButton
                size='large'
                aria-label='mobile navigation menu icon'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Button
                aria-label='mobile navigation menu link'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                sx={{
                  color: 'white',
                  px: '0',
                  justifyContent: 'flex-start',
                }}
              >
                Menu
              </Button>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                {/* Mobile Nav */}
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    fullWidth
                    href='https://alachuacounty.us/Pages/HowCanWeHelpYou.aspx'
                  >
                    How Can We Help You?
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    fullWidth
                    href='https://alachuacounty.us/Depts/Pages/Depts.aspx'
                  >
                    County Offices
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    fullWidth
                    href='https://alachuacounty.us/Businesses/Pages/Businesses.aspx'
                  >
                    Business Resources
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    fullWidth
                    href='https://alachuacounty.us/contactus/Pages/contactus.aspx'
                  >
                    Contact Us
                  </Button>
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Grid container spacing={0}>
                <Grid item xs={0} md={3}>
                  {/* Desktop Nav */}
                  <NavButton
                    href='https://alachuacounty.us/Pages/HowCanWeHelpYou.aspx'
                    fullWidth
                  >
                    How Can We Help You? ▸
                  </NavButton>
                </Grid>
                <Grid item xs={0} md={3}>
                  <NavButton
                    href='https://alachuacounty.us/Depts/Pages/Depts.aspx'
                    fullWidth
                  >
                    County Offices ▸
                  </NavButton>
                </Grid>
                <Grid item xs={0} md={3}>
                  <NavButton
                    href='https://alachuacounty.us/Businesses/Pages/Businesses.aspx'
                    fullWidth
                  >
                    Business Resources ▸
                  </NavButton>
                </Grid>
                <Grid item xs={0} md={3}>
                  <NavButton
                    sx={{ borderRight: '1px solid #330000' }}
                    href='https://alachuacounty.us/contactus/Pages/contactus.aspx'
                    fullWidth
                  >
                    Contact Us ▸
                  </NavButton>
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </Grid>
        <Grid item xs={0} sm={1} xl={2}></Grid>
      </Grid>
    </>
  );
};
export default ResponsiveAppBar;
