import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormsCenterContext } from '../../Context/FormsCenterContext';
import EmployeeFormsForms from './EmployeeFormsForms';

import { useLocation } from 'react-router-dom';

export default function EmployeeForms() {
  const formsObject = useContext(FormsCenterContext);

  const { search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* If user IS NOT authenticated, display login page */}
      {!formsObject.isAuthSAML && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography component='subtitle1'>
            Alachua County employees must{' '}
            <Link
              onClick={() => {
                if (formsObject.isAuthLocal) {
                  axios
                    // removes authentication cookie from browser
                    .get(`${formsObject.passportUrlLocal}/logout`, {
                      withCredentials: true,
                    })
                    .then(async (res) => {
                      //removes userObject/sets isAuth flag to false - see formsCenterContext
                      await formsObject.logout();
                      window.location.href = `${
                        formsObject.passportUrlSAML
                      }/adfs?redirectUrl=${formsObject.containerUrl}${
                        search ? search : ''
                      }`;
                    });
                } else {
                  window.location.href = `${
                    formsObject.passportUrlSAML
                  }/adfs?redirectUrl=${formsObject.containerUrl}${
                    search ? search : ''
                  }`;
                }
              }}
              variant='body2'
              sx={{ cursor: 'pointer' }}
            >
              login to view these forms
            </Link>
          </Typography>
        </Box>
      )}
      {/* If user IS authenticated display Employee Forms */}
      {formsObject.isAuthSAML && <EmployeeFormsForms />}
    </>
  );
}
