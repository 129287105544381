import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading';
import About from './About/About';
import GeneralForms from './GeneralForms';
import EmployeeForms from './EmployeeForms/EmployeeForms';
import MyForms from './MyForms/MyForms';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Heading />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: -10,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          centered={true}
          sx={{
            '& button': {
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              backgroundColor: 'lightgray',
              width: 0.25,
              color: 'black',
            },
            '& button.Mui-selected': {
              backgroundColor: 'white',
              color: 'black',
            },
            '& button:hover': {
              backgroundColor: 'gray',
              color: 'black',
            },
            '& button:focus': {
              backgroundColor: 'white',
              color: 'black',
            },
          }}
        >
          <Tab label='About' {...a11yProps(0)} />
          <Tab label='General Forms' {...a11yProps(1)} />
          <Tab label='Employee Forms' {...a11yProps(2)} />
          <Tab label='My Forms' {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <About setTabValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GeneralForms />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmployeeForms />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MyForms />
      </TabPanel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: 'white',
          backgroundColor: '#3D86C1',
          padding: 5,
          mt: 3,
        }}
      >
        <h2>We are here to assist you.</h2>
        <Typography variant='body1' align='center' sx={{ fontSize: '1.2rem' }}>
          Our online comment form is the quickest and easiest way to get help.
          Simply submit your question or issue, and we'll responsd to you as
          soon as possible.
        </Typography>
        <Button
          target='_blank'
          href='https://alachuacounty.us/contactus/Pages/Contactus.aspx'
          sx={{
            borderRadius: 4,
            color: '#FFFFFF',
            borderColor: '#FFFFFF',
            marginBottom: 3,
            marginTop: 3,
          }}
          variant='outlined'
        >
          CONTACT US
        </Button>
      </Box>
    </>
  );
}
