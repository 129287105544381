import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SignInModal from '../SignIn/SignInModal';
import SignUpModal from '../SignUp/SignUpModal';
import MyFormsForms from './MyFormsForms';
import { FormsCenterContext } from '../../Context/FormsCenterContext';

export default function EmployeeForms() {
  const formsObject = useContext(FormsCenterContext);

  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);

  const handleOpenSignIn = () => setOpenSignIn(true);
  const handleCloseSignIn = () => setOpenSignIn(false);

  const handleOpenSignUp = () => setOpenSignUp(true);
  const handleCloseSignUp = () => setOpenSignUp(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* If user IS NOT authenticated, display log in page */}
      {!(formsObject.isAuthSAML || formsObject.isAuthLocal) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography component='subtitle1'>
            You must{' '}
            <Link
              onClick={handleOpenSignIn}
              variant='body2'
              sx={{ cursor: 'pointer' }}
            >
              Login or Sign Up
            </Link>{' '}
            to view your forms.
          </Typography>
          <SignInModal
            openSignIn={openSignIn}
            handleCloseSignIn={handleCloseSignIn}
            handleOpenSignUp={handleOpenSignUp}
          />
          <SignUpModal
            openSignUp={openSignUp}
            handleCloseSignUp={handleCloseSignUp}
            handleOpenSignIn={handleOpenSignIn}
          />
        </Box>
      )}
      {/* If user IS authenticated display Employee Forms */}
      {(formsObject.isAuthSAML || formsObject.isAuthLocal) && <MyFormsForms />}
    </>
  );
}
