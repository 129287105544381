import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { FormsCenterContext } from './Context/FormsCenterContext';
import HomeIcon from '@mui/icons-material/Home';

function MicroFrontend({ name, host }) {
  const history = useHistory();
  const formsObject = useContext(FormsCenterContext);

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    const renderMicroFrontend = () => {
      window[`render${name}`](`${name}-container`);
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    fetch(`${host}asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${host}${manifest.files['main.js']}`;
        script.onload = () => {
          renderMicroFrontend();
        };
        document.head.appendChild(script);
      });

    return () => {
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
    };
  }, []);
  return (
    <>
      <Button
        variant='text'
        startIcon={<HomeIcon />}
        onClick={() => {
          history.push('/');
          formsObject.setCrumbs([]);
          window.location.reload();
        }}
      >
        Home
      </Button>
      <main id={`${name}-container`} />
    </>
  );
}

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
