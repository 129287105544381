import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormsCenterContext } from '../../Context/FormsCenterContext';

const theme = createTheme();

export default function SignUp({ SignUpClose, SignInOpen }) {
  const formsObject = useContext(FormsCenterContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [open, setOpen] = useState(false);
  const [acEmailAddr, setAcEmailAddr] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [message, setMessage] = useState('');

  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((showConfirm) => !showConfirm);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleACEmailAddrClose = () => {
    setAcEmailAddr(false);
  };

  const handlePasswordClose = () => {
    setPasswordOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email.includes('alachuacounty.us')) {
      setAcEmailAddr(true);
    } else if (password !== passwordConfirm) {
      setPasswordOpen(true);
    } else {
      axios({
        method: 'POST',
        data: {
          firstName,
          lastName,
          email,
          password,
        },
        url: `${formsObject.passportUrlLocal}/signup`,
      })
        .then((res) => {
          console.log('RESULT', res);
          setOpen(true);
          setMessage(res.data.toUpperCase());
          setTimeout(() => {
            history.push('/confirm');
          }, 2000);
        })
        .catch((err) => {
          console.log('ERROR', err);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='md'>
        <CssBaseline />

        <Snackbar
          open={acEmailAddr}
          autoHideDuration={3000}
          onClose={handleACEmailAddrClose}
        >
          <Alert
            onClose={handleACEmailAddrClose}
            severity='error'
            sx={{ width: '100%' }}
          >
            EMAIL ADDRESSES ENDING IN "alachuacounty.us" ARE NOT PERMITTED
          </Alert>
        </Snackbar>

        <Snackbar
          open={passwordOpen}
          autoHideDuration={3000}
          onClose={handlePasswordClose}
        >
          <Alert
            onClose={handlePasswordClose}
            severity='error'
            sx={{ width: '100%' }}
          >
            PASSWORDS DO NOT MATCH
          </Alert>
        </Snackbar>

        {message === 'USER ALREADY EXISTS' && (
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: '100%' }}
            >
              {message}
            </Alert>
          </Snackbar>
        )}

        {message === 'USER CREATED' && (
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
            >
              {message}
            </Alert>
          </Snackbar>
        )}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link
            href='#'
            underline='none'
            onClick={() => SignUpClose()}
            sx={{ position: 'absolute', top: 15, right: 15 }}
          >
            X
          </Link>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>

          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name='email'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl margin='normal' variant='outlined' fullWidth>
                  <InputLabel
                    htmlFor='outlined-adornment-password'
                    variant='filled'
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ paddingTop: '15px' }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin='normal' variant='outlined' fullWidth>
                  <InputLabel
                    htmlFor='outlined-adornment-password-confirm'
                    variant='filled'
                  >
                    Re-Enter Password
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password-confirm'
                    type={showPasswordConfirm ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                          edge='end'
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    sx={{ paddingTop: '15px' }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
                  REGISTER
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link
                  href='#'
                  variant='body2'
                  onClick={() => {
                    SignUpClose();
                    SignInOpen();
                  }}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
