import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import RenameForm from './RenameForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RenameFormModal({
  openRenameForm,
  handleCloseRenameForm,
  formDataId,
  formName,
  setFormName,
}) {
  return (
    <Modal
      open={openRenameForm}
      onClose={handleCloseRenameForm}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        onClick={(e) => e.preventDefault()}
        sx={{
          ...style,
          width: { xs: '90%', sm: '60%', md: '50%', lg: '45%', xl: '40%' },
        }}
      >
        <RenameForm
          formDataId={formDataId}
          formName={formName}
          setFormName={setFormName}
          RenameFormClose={handleCloseRenameForm}
        />
      </Box>
    </Modal>
  );
}
