import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// import headerImage from '../images/header-image.png';
import headerImage from '../images/Banner800x200.png';

export default function Heading() {
  return (
    <Paper
      sx={{
        position: 'relative',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${headerImage})`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,0)',
        }}
      />
      <Box
        display='flex'
        height={200}
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          component='h2'
          variant='h3'
          color='#000000'
          sx={{ zIndex: 1 }}
        >
          Forms Center
        </Typography>
      </Box>
    </Paper>
  );
}
