import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SiteCard from '../SiteCard/SiteCard';
import { FormsCenterContext } from '../../Context/FormsCenterContext';

export default function EmployeeForms() {
  const formsObject = useContext(FormsCenterContext);
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredForms, setFilteredForms] = useState([]);
  const [departments, setDepartments] = useState([]);

  // Logs user out of application
  const logout = async () => {
    await axios.get(`${formsObject.passportUrlSAML}/logout`, {
      withCredentials: true,
    });

    await formsObject.logout();
  };

  // Event handler for filter dropdown
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    // All forms
    if (event.target.value === 'All Forms') {
      let employeeForms = [
        ...formsObject.employeeForms,
        ...formsObject.employeeFormsExternal,
      ];

      setFilteredForms(employeeForms);
    } else {
      // Department
      let employeeForms = [
        ...formsObject.employeeForms,
        ...formsObject.employeeFormsExternal,
      ];

      let filtered = employeeForms.filter(function (form) {
        return form.Departments.includes(event.target.value);
      });
      // Set filteredForms state array
      setFilteredForms(filtered);
    }
  };

  // Event handler for sort dropdown
  const handleSortChange = (event) => {
    setSort(event.target.value);

    let filtered;

    // Sort by date ASC
    if (event.target.value === 'Oldest') {
      filtered = filteredForms.sort((a, b) => {
        if (a.DateAdded < b.DateAdded) {
          return -1;
        }
        if (a.DateAdded > b.DateAdded) {
          return 1;
        }
        return 0;
      });
    }

    // Sort by date DESC
    if (event.target.value === 'Newest') {
      filtered = filteredForms.sort((a, b) => {
        if (a.DateAdded > b.DateAdded) {
          return -1;
        }
        if (a.DateAdded < b.DateAdded) {
          return 1;
        }
        return 0;
      });
    }

    // Sort by Form Name
    if (event.target.value === 'Form Name') {
      filtered = filteredForms.sort((a, b) => {
        if (a.FormName < b.FormName) {
          return -1;
        }
        if (a.FormName > b.FormName) {
          return 1;
        }
        return 0;
      });
    }
    setFilteredForms(filtered);
  };

  // Used to dynamically set the CSS width property
  let userNameLength = 0;
  if (formsObject.user) userNameLength = formsObject.user.UserName.length;

  //fires when formsObject is set
  useEffect(() => {
    const deptSet = new Set();

    let employeeForms = [
      ...formsObject.employeeForms,
      ...formsObject.employeeFormsExternal,
    ];

    setFilteredForms(employeeForms);

    // Iterate over all forms in order to build a unique set of departments
    for (let form of employeeForms) {
      // Get departments for current form
      let departments = form.Departments;
      // Departments is stored in DB as a comma delimited string
      // Convert comma delimited string to array
      let departmentArr = departments.split(',');
      // Iterate over items in array and add to set
      // Items in set will be unique
      for (let departmentName of departmentArr) {
        deptSet.add(departmentName);
      }
    }
    // Convert set to array and sort in ASC order
    setDepartments(Array.from(deptSet).sort());
  }, [formsObject]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 3,
        }}
      >
        {/* Renders log out link */}
        {/* Show if user IS authenticated */}
        {formsObject.isAuthSAML && (
          <Box
            sx={{
              '& .MuiTextField-root': { width: `${userNameLength + 3}ch` },
            }}
            noValidate
            autoComplete='off'
          >
            <TextField
              variant='standard'
              InputProps={{ disableUnderline: true }}
              label={`${formsObject.user && formsObject.user.UserName}`}
              select
            >
              <MenuItem value='Logout' onClick={() => logout()}>
                Logout
              </MenuItem>
            </TextField>
          </Box>
        )}
      </Box>
      {/*<Grid
        container
        sx={{
          marginBottom: 3,
        }}
      >        
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: 2,
            justifyContent: { xs: 'center', md: 'flex-start' },
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '200px',
              display: 'flex',
              paddingRight: '10px',
            }}
          >
            <TextField
              value={filter}
              onChange={handleFilterChange}
              label='Filter By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'All Forms'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                All Forms
              </MenuItem>

              {departments.map((department, index) => (
                <MenuItem
                  key={index}
                  value={department}
                  sx={{
                    fontSize: '.9rem',
                  }}
                >
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ width: '100%', maxWidth: '175px', display: 'flex' }}>
            <TextField
              value={sort}
              onChange={handleSortChange}
              label='Sort By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'Newest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Newest
              </MenuItem>
              <MenuItem
                value={'Oldest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Oldest
              </MenuItem>
              <MenuItem
                value={'Form Name'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Form Name
              </MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        {filteredForms.length > 0 ? (
          // Renders SiteCard/Form for each item in filteredForms
          filteredForms.map((form, index) =>
            form.IsExternal ? (
              // Renders link for external forms - Survey Monkey, Seamless Docs, etcetera
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} lg={4}>
                  <Link
                    to={`/${form.EndPoint}/`}
                    style={{ textDecoration: 'none' }}
                  >
                    <SiteCard
                      department={form.Departments}
                      formName={form.FormName}
                      formDescription={form.FormDescription}
                      isEmployeeForm={form.IsEmployeeForm}
                    />
                  </Link>
                </Grid>
              </React.Fragment>
            ) : (
              // Renders link for MicroFrontend
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} lg={4}>
                  <Link
                    to={`/${form.EndPoint}/?id=${form.FormId}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <SiteCard
                      department={form.Departments}
                      formName={form.FormName}
                      formDescription={form.FormDescription}
                      isEmployeeForm={form.IsEmployeeForm}
                    />
                  </Link>
                </Grid>
              </React.Fragment>
            )
          )
        ) : (
          // Displays message if there are no forms to display
          <Grid item xs={12}>
            <Typography variant='body1'>
              There are currently no <b>Employee Forms</b> available.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
