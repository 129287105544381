import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import SiteCard from '../SiteCard/SiteCard';
import { FormsCenterContext } from '../../Context/FormsCenterContext';
import { Typography } from '@mui/material';

export default function MyForms() {
  const formsObject = useContext(FormsCenterContext);

  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');
  const [departments, setDepartments] = useState([]);
  const [filteredSubmittedForms, setFilteredSubmittedForms] = useState([]);
  const [filteredInprogressForms, setFilteredInprogressForms] = useState([]);

  // Logs user out of application
  const logout = async () => {
    // Authenticated via ADFS - Passport SAML
    if (formsObject.isAuthSAML) {
      // removes authentication cookie from browser
      await axios.get(`${formsObject.passportUrlSAML}/logout`, {
        withCredentials: true,
      });

      await formsObject.logout();
    }

    // Authenticated via username/password - Passport Local
    if (formsObject.isAuthLocal) {
      axios
        // removes authentication cookie from browser
        .get(`${formsObject.passportUrlLocal}/logout`, {
          withCredentials: true,
        })
        .then(async (res) => {
          //removes userObject/sets isAuth flag to false - see formsCenterContext
          await formsObject.logout();
          window.location.reload();
        });
    }
  };

  // Event handler for filter dropdown
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    // All forms
    if (event.target.value === 'All Forms') {
      // Authenticated via ADFS - Passport SAML
      if (formsObject.isAuthSAML) {
        // Set EMPLOYEE Submitted/In Progress Forms
        setFilteredSubmittedForms(formsObject.submittedFormsEmployee);
        setFilteredInprogressForms(formsObject.inProgressFormsEmployee);
      }
      // Authenticated via username/password - Passport Local
      if (formsObject.isAuthLocal) {
        // set CITIZEN Submitted/In Progress Forms
        setFilteredSubmittedForms(formsObject.submittedForms);
        setFilteredInprogressForms(formsObject.inProgressForms);
      }
    } else {
      // Authenticated via ADFS - Passport SAML
      if (formsObject.isAuthSAML) {
        // Department
        let filteredSubmitted = formsObject.submittedFormsEmployee.filter(
          function (form) {
            return form.Departments.includes(event.target.value);
          }
        );

        setFilteredSubmittedForms(filteredSubmitted);

        let filteredInProgress = formsObject.inProgressFormsEmployee.filter(
          function (form) {
            return form.Departments.includes(event.target.value);
          }
        );

        setFilteredInprogressForms(filteredInProgress);
      }

      // Authenticated via username/password - Passport Local
      if (formsObject.isAuthLocal) {
        // Submitted forms by Department
        let filteredSubmitted = formsObject.submittedForms.filter(function (
          form
        ) {
          return form.Departments.includes(event.target.value);
        });

        setFilteredSubmittedForms(filteredSubmitted);

        // In Progress Forms by Department
        let filteredInProgress = formsObject.inProgressForms.filter(function (
          form
        ) {
          return form.Departments.includes(event.target.value);
        });

        setFilteredInprogressForms(filteredInProgress);
      }
    }
  };

  // Event handler for sort dropdown
  const handleSortChange = (event) => {
    setSort(event.target.value);

    let filteredSubmitted;
    let filteredInProgress;

    // Sort form Site Cards by date ASC
    if (event.target.value === 'Oldest') {
      filteredSubmitted = filteredSubmittedForms.sort((a, b) => {
        console.log('a', a);
        console.log('b', b);
        if (a.LastUpdatedDate < b.LastUpdatedDate) {
          return -1;
        }
        if (a.LastUpdatedDate > b.LastUpdatedDate) {
          return 1;
        }
        return 0;
      });

      filteredInProgress = filteredInprogressForms.sort((a, b) => {
        if (a.LastUpdatedDate < b.LastUpdatedDate) {
          return -1;
        }
        if (a.LastUpdatedDate > b.LastUpdatedDate) {
          return 1;
        }
        return 0;
      });
    }

    // Sort form Site Cards by date DESC
    if (event.target.value === 'Newest') {
      filteredSubmitted = filteredSubmittedForms.sort((a, b) => {
        if (a.LastUpdatedDate > b.LastUpdatedDate) {
          return -1;
        }
        if (a.LastUpdatedDate < b.LastUpdatedDate) {
          return 1;
        }
        return 0;
      });

      filteredInProgress = filteredInprogressForms.sort((a, b) => {
        if (a.LastUpdatedDate > b.LastUpdatedDate) {
          return -1;
        }
        if (a.LastUpdatedDate < b.LastUpdatedDate) {
          return 1;
        }
        return 0;
      });
    }

    // Sort form Site Cards by Name
    if (event.target.value === 'Form Name') {
      filteredSubmitted = filteredSubmittedForms.sort((a, b) => {
        if (a.FormName < b.FormName) {
          return -1;
        }
        if (a.FormName > b.FormName) {
          return 1;
        }
        return 0;
      });

      filteredInProgress = filteredInprogressForms.sort((a, b) => {
        if (a.FormName < b.FormName) {
          return -1;
        }
        if (a.FormName > b.FormName) {
          return 1;
        }
        return 0;
      });
    }
    setFilteredSubmittedForms(filteredSubmitted);
    setFilteredInprogressForms(filteredInProgress);
  };

  // Used to dynamically set the CSS width property
  let userNameLength = 0;
  if (formsObject.user) userNameLength = formsObject.user.UserName.length;

  //fires when formsObject is set
  useEffect(() => {
    const deptSet = new Set();

    if (formsObject.isAuthSAML) {
      setFilteredSubmittedForms(formsObject.submittedFormsEmployee);
      setFilteredInprogressForms(formsObject.inProgressFormsEmployee);
    }

    if (formsObject.isAuthLocal) {
      setFilteredSubmittedForms(formsObject.submittedForms);
      setFilteredInprogressForms(formsObject.inProgressForms);
    }

    let forms = [];

    if (formsObject.isAuthSAML) {
      forms = [
        ...formsObject.submittedFormsEmployee,
        ...formsObject.inProgressFormsEmployee,
      ];
    }

    if (formsObject.isAuthLocal) {
      forms = [...formsObject.submittedForms, ...formsObject.inProgressForms];
    }

    // Iterate over all forms in order to build a unique set of departments
    for (let form of forms) {
      // Get departments for current form
      let departments = form.Departments;
      // Departments is stored in DB as a comma delimited string
      // Convert comma delimited string to array
      let departmentArr = departments.split(',');
      // Iterate over items in array and add to set
      // Items in set will be unique
      for (let departmentName of departmentArr) {
        deptSet.add(departmentName);
      }
    }
    setDepartments(Array.from(deptSet).sort());
  }, [formsObject]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 3,
        }}
      >
        {/* Renders log out link */}
        {/* Show if user IS authenticated */}
        {(formsObject.isAuthSAML || formsObject.isAuthLocal) && (
          <Box
            sx={{
              '& .MuiTextField-root': { width: `${userNameLength + 3}ch` },
            }}
            noValidate
            autoComplete='off'
          >
            <TextField
              variant='standard'
              InputProps={{ disableUnderline: true }}
              label={`${formsObject.user && formsObject.user.UserName}`}
              select
            >
              <MenuItem value='Logout' onClick={() => logout()}>
                Logout
              </MenuItem>
            </TextField>
          </Box>
        )}
      </Box>

      {/*<Grid
        container
        sx={{
          marginBottom: 3,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: 2,
            justifyContent: { xs: 'center', md: 'flex-start' },
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '200px',
              display: 'flex',
              paddingRight: '10px',
            }}
          >
            <TextField
              value={filter}
              onChange={handleFilterChange}
              label='Filter By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'All Forms'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                All Forms
              </MenuItem>

              {departments.map((department, index) => (
                <MenuItem
                  key={index}
                  value={department}
                  sx={{
                    fontSize: '.9rem',
                  }}
                >
                  {department}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ width: '100%', maxWidth: '175px', display: 'flex' }}>
            <TextField
              value={sort}
              onChange={handleSortChange}
              label='Sort By'
              select
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: {
                    '.MuiList-root': {
                      padding: 0,
                      maxWidth: '200px',
                    },
                  },
                },
              }}
              sx={{
                '& .MuiFormLabel-root': {
                  color: '#333333',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '.8rem',
                  top: '-3px',
                },
                '& .MuiSelect-select': {
                  padding: '10px',
                },
              }}
            >
              <MenuItem
                value={'Newest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Newest
              </MenuItem>
              <MenuItem
                value={'Oldest'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Oldest
              </MenuItem>
              <MenuItem
                value={'Form Name'}
                sx={{
                  fontSize: '.9rem',
                }}
              >
                Form Name
              </MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>*/}

      <Typography variant='body1' sx={{ marginBottom: 4 }}>
        <b>Note:</b> Not all forms are configured to allow for incremental work
        nor be viewed after submission.
      </Typography>
      {filteredInprogressForms.length !== 0 && (
        <>
          {/* Render In Progress Site Cards */}
          <Typography component='h3' variant='h5' align='center'>
            In Progress
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: 2, marginBottom: 4 }}>
            {filteredInprogressForms.length > 0 ? (
              filteredInprogressForms.map((form, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Link
                      to={`/${form.EndPoint}/?id=${form.FormId}&formDataId=${form.FormDataID}&formState=notSubmitted`}
                      style={{ textDecoration: 'none' }}
                    >
                      <SiteCard
                        department={form.Departments}
                        formName={form.CustomFormName || form.FormName}
                        formDescription={form.FormDescription}
                        isEmployeeForm={form.IsEmployeeForm}
                        lastUpdatedDate={form.LastUpdatedDate}
                        formDataId={form.FormDataID}
                      />
                    </Link>
                  </Grid>
                </React.Fragment>
              ))
            ) : (
              // Message if there are no Site Cards to render
              <Grid item xs={12}>
                <Typography variant='body1'>
                  There are currently no <b>In Progress Forms</b> available to
                  view.
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {filteredSubmittedForms.length !== 0 && (
        <>
          {/* Render Submitted Site Cards */}
          <Typography component='h3' variant='h5' align='center'>
            Submitted
          </Typography>
          <Grid container spacing={3} sx={{ marginTop: 2 }}>
            {filteredSubmittedForms.length > 0 ? (
              filteredSubmittedForms.map((form, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Link
                      to={`/${form.EndPoint}/?id=${form.FormId}&formDataId=${form.FormDataID}&formState=submitted`}
                      style={{ textDecoration: 'none' }}
                    >
                      <SiteCard
                        department={form.Departments}
                        formName={form.CustomFormName || form.FormName}
                        formDescription={form.FormDescription}
                        isEmployeeForm={form.IsEmployeeForm}
                        lastUpdatedDate={form.LastUpdatedDate}
                        formDataId={form.FormDataID}
                        submitted
                      />
                    </Link>
                  </Grid>
                </React.Fragment>
              ))
            ) : (
              // Message if there are no Site Cards to render
              <Grid item xs={12}>
                <Typography variant='body1'>
                  There are currently no <b>Submitted Forms</b> available to
                  view.
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}
