import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const FormsCenterContext = createContext();

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

// URL of MFE container
// const containerUrl = 'https://ads84.alachuacounty.us';
const containerUrl = "https://forms.alachuacounty.us";
// Root URL of MFE application
// const mfeUrl = 'https://ads85.alachuacounty.us';
const mfeUrl = "https://info.alachuacounty.us";

// Passport SAML endpoint
const passportUrlSAML =
  // 'https://ads84.alachuacounty.us/passport-backend-saml/auth';
  "https://forms.alachuacounty.us/passport-backend-saml/auth";

// Passport Local endpoint
const passportUrlLocal =
  // 'https://ads84.alachuacounty.us/passport-backend-local/auth';
  "https://api.alachuacounty.us/passport-backend-local/auth";

// Forms accessible to both employees and citizens
const getGeneralFormsURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getGeneralForms';
  "https://api.alachuacounty.us/forms-center-backend/api/getGeneralForms";

const getGeneralFormsExternalURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getGeneralFormsExternal';
  "https://api.alachuacounty.us/forms-center-backend/api/getGeneralFormsExternal";

// Forms accessible to only employees
// Employee's user id will be passed as a parameter
const getEmployeeFormsURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getEmployeeForms';
  "https://api.alachuacounty.us/forms-center-backend/api/getEmployeeForms";

const getEmployeeFormsExternalURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getEmployeeFormsExternal';
  "https://api.alachuacounty.us/forms-center-backend/api/getEmployeeFormsExternal";

// Forms initiated by a CITIZEN that are in an INCOMPLETE state
// Citizen's user id will be passed as a parameter
const getInProgressFormsURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getInProgressForms';
  "https://api.alachuacounty.us/forms-center-backend/api/getInProgressForms";

// Forms initiated by an EMPLOYEE that are in an INCOMPLETE state
// Employee's user id will be passed as a parameter
const getInProgressFormsEmployeeURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getInProgressFormsEmployee';
  "https://api.alachuacounty.us/forms-center-backend/api/getInProgressFormsEmployee";

// Forms initiated by a CITIZEN that are in an SUBMITTED state
// Citizen's user id will be passed as a parameter
const getSubmittedFormsURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getSubmittedForms';
  "https://api.alachuacounty.us/forms-center-backend/api/getSubmittedForms";

// Forms initiated by an EMPLOYEE that are in an SUBMITTED state
// Employee user id will be passed as a parameter
const getSubmittedFormsEmployeeURL =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getSubmittedFormsEmployee';
  "https://api.alachuacounty.us/forms-center-backend/api/getSubmittedFormsEmployee";

const putCustomFormName =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/putCustomFormName';
  "https://api.alachuacounty.us/forms-center-backend/api/putCustomFormName";

const ForgetPasswordSendEmail =
  // 'https://ads85.alachuacounty.us/forms-center-backend/api/getForgetPasswordSendEmail';
  "https://api.alachuacounty.us/forms-center-backend/api/getForgetPasswordSendEmail";

//Send email with userid
const forgtPasswordSendEmail = (emailAddress) => {
  try {
    axios.get(ForgetPasswordSendEmail, {
      params: { emailAddress },
    });
  } catch (error) {
    console.log(error);
  }
};

export function FormsCenterProvider({ children }) {
  const [userObject, setUserObject] = useState(null);
  const [isAuthSAML, setIsAuthSAML] = useState(false);
  const [isAuthLocal, setIsAuthLocal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); //flag set while data asynchronously loads
  const [generalForms, setGeneralForms] = useState([]);
  const [generalFormsExternal, setGeneralFormsExternal] = useState([]);
  const [employeeForms, setEmployeeForms] = useState([]);
  const [employeeFormsExternal, setEmployeeFormsExternal] = useState([]);
  const [inProgressForms, setInProgressForms] = useState([]);
  const [inProgressFormsEmployee, setInProgressFormsEmployee] = useState([]);
  const [submittedForms, setSubmittedForms] = useState([]);
  const [submittedFormsEmployee, setSubmittedFormsEmployee] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  // Called when user logs out
  const clearUserObject = async () => {
    await setUserObject(null);
    await setIsAuthSAML(false);
    await setIsAuthLocal(false);
    window.location.reload();
  };

  // Runs on load
  useEffect(() => {
    // Gets General Forms
    const getGeneralForms = axios.get(getGeneralFormsURL);
    const getGeneralFormsExternal = axios.get(getGeneralFormsExternalURL);
    // Gets Employee Forms
    const getEmployeeForms = axios.get(getEmployeeFormsURL);
    const getEmployeeFormsExternal = axios.get(getEmployeeFormsExternalURL);

    // Determines if EMPLOYEE is authenticated via ADFS - Passport SAML
    const isAuthSAML = axios.get(
      `${passportUrlSAML}/getUser?timestamp=${new Date().getTime()}`,
      {
        withCredentials: true,
      }
    );

    // Determines if CITIZEN has authenticated via username and password - Passport Local
    const isAuthLocal = axios.get(
      `${passportUrlLocal}/getUser?timestamp=${new Date().getTime()}`,
      {
        withCredentials: true,
      }
    );

    // Wait for all promises to resolve
    Promise.all([
      getGeneralForms,
      getGeneralFormsExternal,
      getEmployeeForms,
      getEmployeeFormsExternal,
      isAuthSAML,
      isAuthLocal,
    ]).then((result) => {
      const [
        getGeneralFormsData,
        getGeneralFormsExternalData,
        getEmployeeFormsData,
        getEmployeeFormsExternalData,
        isAuthSAMLData,
        isAuthLocalData,
      ] = result;

      // If there exists General Forms data
      if (getGeneralFormsData.data) {
        // set generalForms state array
        setGeneralForms(getGeneralFormsData.data);
      }

      if (getGeneralFormsExternalData.data) {
        // set generalFormsExternal state array
        setGeneralFormsExternal(getGeneralFormsExternalData.data);
      }

      // If there exists Employee Forms data
      if (getEmployeeFormsExternalData.data) {
        // set employeeFormsExternal state array
        setEmployeeFormsExternal(getEmployeeFormsExternalData.data);
      }

      if (getEmployeeFormsData.data) {
        // set employeeForms state array
        setEmployeeForms(getEmployeeFormsData.data);
      }

      // If user is authenticated via ADFS - Passport SAML
      if (isAuthSAMLData.data) {
        // Get InProgress Forms for logged in EMPLOYEE
        const getInProgressFormsEmployee = axios.get(
          getInProgressFormsEmployeeURL,
          { params: isAuthSAMLData.data }
        );
        // Get Submitted Forms for logged in EMPLOYEE
        const getSubmittedFormsEmployee = axios.get(
          getSubmittedFormsEmployeeURL,
          { params: isAuthSAMLData.data }
        );

        // Wait for all promises to resolve
        Promise.all([
          getInProgressFormsEmployee,
          getSubmittedFormsEmployee,
        ]).then((result) => {
          const [
            getInProgressFormsEmployeeData,
            getSubmittedFormsEmployeeData,
          ] = result;

          // Adds userData object returned from passport to session storage
          // This will allow MFEs to write the user's id to any data inserted into FormData table
          sessionStorage.setItem(
            "userData",
            JSON.stringify(isAuthSAMLData.data)
          );
          setUserObject(isAuthSAMLData.data);
          setIsAuthSAML(true);

          // In Progress form data for EMPLOYEE
          if (getInProgressFormsEmployeeData.data) {
            // Set inProgressFormsEmployee state variable
            setInProgressFormsEmployee(getInProgressFormsEmployeeData.data);
          }

          // Submitted form data for EMPLOYEE
          if (getSubmittedFormsEmployeeData.data) {
            // Set submittedFormsEmployee state variable
            setSubmittedFormsEmployee(getSubmittedFormsEmployeeData.data);
          }

          setIsLoading(false); // Asynchronous data load complete
        });
      }
      // If user has authenticated via username and password - Passport Local
      else if (isAuthLocalData.data) {
        const getInProgressForms = axios.get(getInProgressFormsURL, {
          params: isAuthLocalData.data,
        });
        const getSubmittedForms = axios.get(getSubmittedFormsURL, {
          params: isAuthLocalData.data,
        });

        // Wait for all promises to resolve
        Promise.all([getInProgressForms, getSubmittedForms]).then((result) => {
          const [getInProgressFormsData, getSubmittedFormsData] = result;

          // Adds userData object returned from passport to session storage
          // This will allow MFEs to write the user's id to any data inserted into FormsCenter.FormData table
          sessionStorage.setItem(
            "userData",
            JSON.stringify(isAuthLocalData.data)
          );
          setUserObject(isAuthLocalData.data);
          setIsAuthLocal(true);

          // In progress form data for CITIZEN
          if (getInProgressFormsData.data) {
            // Set inProgressForms state variable
            setInProgressForms(getInProgressFormsData.data);
          }

          // Submitted form data for CITIZEN
          if (getSubmittedFormsData.data) {
            //Set submittedForms state variable
            setSubmittedForms(getSubmittedFormsData.data);
          }
          setIsLoading(false); // Asynchronous data load complete
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <FormsCenterContext.Provider
      value={{
        crumbs,
        setCrumbs,
        forgtPasswordSendEmail,
        isLoading,
        containerUrl,
        mfeUrl,
        passportUrlSAML,
        passportUrlLocal,
        isAuthSAML,
        isAuthLocal,
        user: userObject,
        logout: clearUserObject,
        generalForms,
        generalFormsExternal,
        employeeForms,
        employeeFormsExternal,
        inProgressForms,
        inProgressFormsEmployee,
        submittedForms,
        submittedFormsEmployee,
        putCustomFormName,
      }}
    >
      {children}
    </FormsCenterContext.Provider>
  );
}
